.sidebar {
  padding: 14px !important;
  position: fixed; // added to fix sidebar in place so it cannot be scrolled

  .nav-header {
    color: #989898;
    padding: 30px 30px 5px 14px;
    font-size: 11px;
  }

  .nav-link {
    i {
      padding-right: 10px;
    }
  }
}