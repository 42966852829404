.navbar {
  border-bottom: 1px solid #e2e2e2;

  .nav-header-title {
    font-size: 22px;
    font-weight: bold;
    margin-left: 8px;
  }

  .profile-icon {
    font-size: 25px !important;
   
  }
  .dropdown-toggle::after {
    margin-left: 0px !important;
  }

  .lang-selector {
    margin-top: 5px;
    
    .lang-selector-button {
      color: #343a40 !important;
      background-color: #fff !important;
      border-color: #fff !important;
      box-shadow: none !important;
      padding: 10px !important;
  
      .lang-code {
        margin-left: 5px;
      }
  
      img {
        vertical-align: top !important;
      }
  
      &::after {
        content: none !important;
      }
  
      @media (max-width: 991px) {
        margin-right: 15px;
      }
    }
  
    .lang-selector-items {
      inset: -15px auto auto -110px !important;
      
      img {
        margin: -3px 5px 0 0;
      }
      
      .dropdown-item:active {
        color: #4477aa !important;
      }
  
      @media (max-width: 991px) {
        inset: -15px auto auto -15px !important;
      }
    }
  }
}