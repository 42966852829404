.extra-info {
  float: right;

  .fa-question-circle {
    margin-left: 5px;
  }

  .error-msg {
    font-size: 13px;
    color: rgb(255, 0, 0);
  }
}

// .max-form-width {
//   max-width: 1200px;
// }

.action-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  max-width: 1200px;
}

.new-line-modal {
  max-width: 1000px !important;
}

.form-control[disabled] {
  background-color: #e9ecefb3 !important;
}

.tooltip {
  z-index: 99999;
}

.sub-card {
  border-style: solid !important;
  border-color: rgba(0, 0, 0, 0.15) !important;
  border-width: 1px !important;
  border-radius: 5px !important;
  background-color: #fff !important;
  box-shadow: 0 4px 8px 0 RGB(34 36 38 / 10%) !important;
  margin-bottom: 25px;
}

.react-datepicker-wrapper {
  width: 100% !important;

  input {
    height: 3.175rem;
    padding: 0.4375rem 0.75rem;
    line-height: 1.5;
    border-radius: 0.3rem;
  }
}

.tool-tip {
  float: right;
  color: #8097a9;
  font-size: 16px;
  padding-left: 6px;
}

.brand-logo-mini {
  img { 
    height: 50px;
  }
}

.center-contents {
  text-align: center;
}

.table-bordered {
  border: 1px solid #dee2e6 !important;
}

.sidebar-icon-only {
  .main-panel {
    margin-left: 70px;
  }
}

.main-panel {
  margin-left: 270px;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;

  @media screen and (max-width: 991px) {
    margin-left: 0px !important;
  }
}

.full-page-wrapper {
  .main-panel {
    margin-left: 0px !important;
  }
  .spinner-wrapper {
    display: none !important;
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    -ms-transition: none !important;
    transition: none !important;
  }
}

input, select {
  font-size: 16px !important;
}

select {
  color: #495057 !important;
}

label {
  margin-bottom: 5px !important;
}

// .restricted {
//   text-align: center;
//   margin-top: 75px;
// }

// .restricted-card {
//   height: 300px;
// }

.input-group.field-error {
  outline: 1px solid red;
  outline-offset: -2px;
  border-radius: 6px;
}

.form-control.field-error {
  outline: 1px solid red;
  outline-offset: -1px;
  border-radius: 6px;
}

.site-footer {
  color: #7c90a4;
  margin-top: 20px;
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 14px;

  a {
    color: #4e7ba8;
  }

  a:hover {
    color: #0080ff;
  }
}